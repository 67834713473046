import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7944495ba280454c80e73647c55b8cbe@o617827.ingest.sentry.io/5751258",
  release: "559b7f4a34d19e794f5b94142400d1d7174e13a0",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
